import React from "react";
import { graphql } from "gatsby";
import StorytellingPage from "../../components/pages/kit";

const Storytelling = (props) => <StorytellingPage {...props} />;

export const query = graphql`
  query StorytellingQuery {
    revfam: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "review" }
        tag_list: { in: "p-storytelling-fam" }
      }
    ) {
      nodes {
        slug
        name
        lang
        field_component
        tag_list
        content
      }
    }
    revbab: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "review" }
        tag_list: { in: "p-storytelling-tog" }
      }
    ) {
      nodes {
        slug
        name
        lang
        field_component
        tag_list
        content
      }
    }
    revtog: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "review" }
        tag_list: { in: "p-storytelling-bab" }
      }
    ) {
      nodes {
        slug
        name
        lang
        field_component
        tag_list
        content
      }
    }
  }
`;

export default Storytelling;
